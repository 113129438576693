<template>
  <div class="panel-title">
    <r-row wrap :row-gap="{ widest: 24, wide: 20, middle: 16, narrow: 12 }">
      <r-col :cols="{ widest: 12, wide: 12, middle: 12, narrow: 12 }">
        <h2 v-line-clamp:44="2" v-if="info.title && $currentConfig.header.info.title" class="cheddar harakiri--text" style="word-break: normal;">
          {{ info.title }}
        </h2>

        <div v-if="info.address && $currentConfig.header.info.address" class="feta metropolis--text" style="margin-top: 8px;">
          {{ info.address }}
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script>
export default {
  name: "PanelTitle",

  props: {
    info: {
      type: Object,
      default: () => []
    }
  },
}
</script>

<style lang="scss">
.panel-title {
  padding-top: 8px;
  padding-bottom: 16px;
}
</style>
