import axios from "axios";
import i18n from "@/i18n";

const state = {
  stories: [],
};

const getters = {
  GET_STORIES: state => state.stories,
};

const mutations = {
  SET_STORIES: (state, payload) => {
    state.stories = payload;
  },
}

const actions = {
  GET_STORIES_FROM_SERVER: async ({ commit }, id) => {
    const singleCity = id ? `&city_id=${id}` : '';
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/stories?resolution=small&lang=${i18n.locale}${singleCity}`);
    commit('SET_STORIES', data.data);
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
};
