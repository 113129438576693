import axios from 'axios';
import i18n from '@/i18n';
import moment from 'moment';

const state = {
  events: [],
  filteredEvents: [],
};

const getters = {
  GET_EVENTS: state => state.events,
  GET_FILTERED_EVENTS: state => state.filteredEvents,
};

const mutations = {
  SET_EVENTS: (state, payload) => {
    state.events = payload;
  },
  SET_FILTERED_EVENTS: (state, payload) => {
    state.filteredEvents = payload;
  },
  ADD_SINGLE_EVENT: (state, payload) => {
    state.events.push(payload);
  }
}

const actions = {
  GET_EVENTS_FROM_SERVER: async ({ commit }, id) => {
    const singleCity = id ? `&city_id=${id}` : '';
    const today = moment().format('YYYY-MM-DD');

    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/events?started_at=${today}${singleCity}&lang=${i18n.locale}&count=1000&sort=created_at&resolution=small`);
    commit('SET_EVENTS', data.data);
  },
  GET_FILTERED_EVENTS_FROM_SERVER: async ({ commit }, payload) => {
    const singleCity = payload.cityId ? `&city_id=${payload.cityId}` : '';
    const started_at = payload.date[0] ? `&started_at=${payload.date[0]}` : '';
    const stopped_at = payload.date[1] ? `&stopped_at=${payload.date[1]}` : '';
    const category_id = payload.category ? `&category_id=${payload.category}` : '';

    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/events?${started_at}${stopped_at}${singleCity}${category_id}&lang=${i18n.locale}&count=1000&sort=created_at&resolution=small`);
    commit('SET_FILTERED_EVENTS', data.data);
  },
  GET_SINGLE_EVENT_FROM_SERVER: async ({ commit }, id) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/events/${id}`);
    commit('ADD_SINGLE_EVENT', data.data);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
