const state = {
  arrows: [],
};

const getters = {
  GET_ARROWS: state => state.arrows,
};

const mutations = {
  SET_ARROWS: (state, payload) => {
    state.arrows = payload;
  },
}

export default {
  state,
  getters,
  mutations,
};
