import axios from "axios";

const state = {
  panel: {},
  singleCity: null,
  headPanelHeight: null,
  isLoading: false,
};

const getters = {
  GET_PANEL: state => state.panel,
  GET_SINGLE_CITY: state => state.singleCity,
  GET_HEAD_HEIGHT: state => state.headPanelHeight,
  GET_IS_LOADING: state => state.isLoading,
};

const mutations = {
  SET_PANEL: (state, payload) => {
    state.panel = payload;
  },
  SET_SINGLE_CITY: (state, payload) => {
    state.singleCity = payload;
  },
  SET_HEAD_HEIGHT: (state, payload) => {
    state.headPanelHeight = payload;
  },
  SET_IS_LOADING: (state, payload) => {
    state.isLoading = payload;
  }
}

const actions = {
  GET_PANEL_FROM_SERVER: async ({ commit }, id) => {
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/panels/${id}`);
    commit('SET_PANEL', data.data);
  },
  GET_SINGLE_CITY_FROM_SERVER: async ({ commit }) => {
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/cities?single=true`);
    commit('SET_SINGLE_CITY', data.data);
  },
  SET_LOADING: async ({ commit }, isLoading) => {
    commit('SET_IS_LOADING', isLoading);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
