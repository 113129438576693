import axios from 'axios';
import config from './config';
import i18n from '@/i18n';
import moment from 'moment';

const show_log = true;
//const res_count = 1000;

class API {
  // Weather
  getWeather() {
    const lat = process.env.VUE_APP_WEATHER_LAT;
    const lon = process.env.VUE_APP_WEATHER_LON;

    return axios
      .get(`https://api.openweathermap.org/data/2.5/weather?units=metric&lang=${i18n.locale}&lat=${lat}&lon=${lon}&appid=8a9b4c75a1373ba213555dd6788d99ba`)
      .then(res => res.data);
  }

  // content
  getCategoryCollection(type, collection) {
    return this.get(`/category-collections/${type}/${collection}`);
  }

  getCategories(type, singleCityId) {
    const singleCity = singleCityId ? `&city_id=${singleCityId}` : '';
    return this.get(`/categories/${type}?lang=${i18n.locale}${singleCity}`)
  }

  // search
  getSearchResults(query, id) {
    const singleCity = id ? `&city_id=${id}` : '';
    const today = moment().format('YYYY-MM-DD');
    return this.get(`/search?query=${query}${singleCity}&started_at=${today}`);
  }

  // General API interfaces
  get(url) {
    return new Promise((resolve, reject) => {
      if (show_log)
        console.log(
          "%c " + new Date().toISOString() + " | API request, GET: " + url,
          "background: #222; color: #bada55"
        );
      axios
        .get(`${url}`, config)
        .then(
          response => {
            resolve(response.data);
          },
          err => {
            reject(err);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      if (show_log)
        console.log(
          "%c " + new Date().toISOString() + " | API request, POST: " + url,
          "background: #222; color: #bada55"
        );
      axios
        .post(url, data, config)
        .then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(url) {
    return new Promise((resolve, reject) => {
      if (show_log)
        console.log(
          "%c " + new Date().toISOString() + " | API request, DELETE: " + url,
          "background: #222; color: #bada55"
        );
      axios
        .delete(url, config)
        .then(
          response => {
            resolve(response.data);
          },
          err => {
            reject(err);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }
}

export default API;
