<template>
  <div class="arrival-card">
    <div class="arrival-card__color">
      <svg width="6" height="28" viewBox="0 0 6 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33198 0.499634C0.480731 2.14732 0 4.01749 0 5.99995V22C0 23.9824 0.480731 25.8526 1.33198 27.5003C4.17146 25.3056 6 21.8663 6 18V9.99995C6 6.13357 4.17146 2.69426 1.33198 0.499634Z" :fill="isArrival ? '#4480F3' : '#D4D5D9'"/>
      </svg>
    </div>

    <div class="arrival-card__no burrata">
      {{ arrival.no }}
    </div>

    <div class="arrival-card__status" :class="isArrival ? 'taleggio' : 'brie'">
      {{ arrivalTime }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ArrivalCard",

  props: {
    arrival: {
      type: [Array, Object],
      default: () => {},
    }
  },

  computed: {
    arrivalTime: function() {
      return this.arrival.status < 1 ? this.$t('transport.arrival') : `${this.arrival.status} ${this.$t('transport.minutes')}.`;
    },
    isArrival: function() {
      return this.arrival.status < 1;
    },
  },
}
</script>

<style lang="scss">
.arrival-card {
  position: relative;

  height: 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;

  background: var(--rir-apocalypse);
  border-radius: 12px;

  padding: 0 12px 0 18px;

  &__color {
    position: absolute;

    left: 0;
    top: calc(50% - 13.5px);

    width: 6px;
    height: 27px;
  }

  &__no {
    color: var(--rir-harakiri);
  }

  &__status {
    color: var(--rir-harakiri);
  }
}
</style>
