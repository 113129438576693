import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import isEmpty from 'lodash.isempty';

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "StartScreen",
    component: () => import("@/views/StartScreen"),
  },
  {
    path: "/sleep",
    name: "SleepScreen",
    component: () => import("@/views/SleepScreen"),
  },
  {
    path: "/search",
    name: "SearchScreen",
    component: () => import("@/views/SearchScreen"),
  },
  {
    path: "/transport",
    name: "TransportScreen",
    component: () => import("@/views/TransportScreen"),
  },
  {
    path: "/events",
    name: "EventsListView",
    component: () => import("@/views/ListScreens/EventsListView"),
  },
  {
    path: "/events/:id",
    name: "DetailedEventView",
    component: () => import("@/views/DetailedScreens/DetailedEventView"),
    beforeEnter: (to, from, next) => {
      checkItemAvailability(to, from, next, 'events');
    },
  },
  {
    path: "/routes",
    name: "RoutesListView",
    component: () => import("@/views/ListScreens/RoutesListView"),
  },
  {
    path: "/routes/:id",
    name: "DetailedRouteView",
    component: () => import("@/views/DetailedScreens/DetailedRouteView"),
    beforeEnter: (to, from, next) => {
      checkItemAvailability(to, from, next, 'routes');
    },
  },
  {
    path: "/places",
    name: "PlacesListView",
    component: () => import("@/views/ListScreens/PlacesListView"),
  },
  {
    path: "/places/:id",
    name: "DetailedPlaceView",
    component: () => import("@/views/DetailedScreens/DetailedPlaceView"),
    beforeEnter: (to, from, next) => {
      checkItemAvailability(to, from, next, 'places');
    },
  },
  {
    path: "/travel-agencies-and-guides",
    name: "AgenciesAndGuidesListView",
    component: () => import("@/views/ListScreens/AgenciesAndGuidesListView"),
  },
  {
    path: "/travel-agencies-and-guides/:id",
    name: "DetailedAgencyAndGuideView",
    component: () => import("@/views/DetailedScreens/DetailedAgencyAndGuideView"),
    beforeEnter: (to, from, next) => {
      checkItemAvailability(to, from, next, 'travel-agencies-and-guides');
    },
  },
  {
    path: "/excursions",
    name: "ExcursionsListView",
    component: () => import("@/views/ListScreens/ExcursionsListView"),
  },
  {
    path: "/excursions/:id",
    name: "DetailedExcursionView",
    component: () => import("@/views/DetailedScreens/DetailedExcursionView"),
    beforeEnter: (to, from, next) => {
      checkItemAvailability(to, from, next, 'excursions');
    },
  },
  {
    path: "/news",
    name: "NewsListView",
    component: () => import("@/views/ListScreens/NewsListView"),
  },
  {
    path: "/news/:id",
    name: "DetailedNewsView",
    component: () => import("@/views/DetailedScreens/DetailedNewsView"),
    beforeEnter: (to, from, next) => {
      checkItemAvailability(to, from, next, 'news');
    },
  },
  {
    path: "/category-collections/:type/:collection",
    name: "CategoryCollectionView",
    component: () => import("@/views/ListScreens/CategoryCollectionView"),
  },
  {
    path: "/story",
    name: "StoryView",
    props: route => ({ query: route.query.id }),
    component: () => import("@/views/StoryView"),
  },
  {
    path: "/services",
    name: "ServiceContactsScreen",
    component: () => import("@/views/ServiceContactsScreen"),
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: () => import("@/views/NotFound"),
  },
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

// Перед переходом на детальную станицу проверяем есть ли она в хранилище
const checkItemAvailability = async function(to, from, next, entity) {
  let currentEntity;
  
  switch(entity) {
    case 'events': {
      currentEntity = store.getters.GET_EVENTS;
      break;
    }
    case 'news': {
      currentEntity = store.getters.GET_NEWS;
      break;
    }
    case 'places': {
      currentEntity = store.getters.GET_PLACES;
      break;
    }
    case 'routes': {
      currentEntity = store.getters.GET_ROUTES;
      break;
    }
    case 'excursions': {
      currentEntity = store.getters.GET_EXCURSIONS;
      break;
    }
    case 'travel-agencies-and-guides': {
      currentEntity = [...store.getters.GET_TRAVEL_AGENCIES, ...store.getters.GET_GUIDES];
      break;
    }
  }
  
  if (currentEntity.some(item => item.id == to.params.id)) {
    next();
  } else {
    await updateStore(to, next, entity);
  }
}

// Если данные детальной страницы не были найдены в хранилище делаем запрос на обновление данных с сервера и добавляем отсутствующий элемент в хранилище
const updateStore = async function(to, next, entity) {
  store.dispatch('SET_LOADING', false);
  
  switch(entity) {
    case 'events': {
      await store.dispatch('GET_SINGLE_EVENT_FROM_SERVER', to.params.id).then(() => {
        store.dispatch('SET_LOADING', true);
        next();
      }).catch(() => {
        store.dispatch('SET_LOADING', true);
        next({ path: '/notfound' });
      });
      break;
    }
    case 'news': {
      await store.dispatch('GET_SINGLE_NEWS_FROM_SERVER', to.params.id).then(() => {
        store.dispatch('SET_LOADING', true);
        next();
      }).catch(() => {
        store.dispatch('SET_LOADING', true);
        next({ path: '/notfound' });
      });
      break;
    }
    case 'places': {
      await store.dispatch('GET_SINGLE_PLACE_FROM_SERVER', to.params.id).then(() => {
        store.dispatch('SET_LOADING', true);
        next();
      }).catch(() => {
        store.dispatch('SET_LOADING', true);
        next({ path: '/notfound' });
      });
      break;
    }
    case 'routes': {
      await store.dispatch('GET_SINGLE_ROUTE_FROM_SERVER', to.params.id).then(() => {
        store.dispatch('SET_LOADING', true);
        next();
      }).catch(() => {
        store.dispatch('SET_LOADING', true);
        next({ path: '/notfound' });
      });
      break;
    }
    case 'excursions': {
      await store.dispatch('GET_SINGLE_EXCURSION_FROM_SERVER', to.params.id).then(() => {
        store.dispatch('SET_LOADING', true);
        next();
      }).catch(() => {
        store.dispatch('SET_LOADING', true);
        next({ path: '/notfound' });
      });
      break;
    }
    case 'travel-agencies-and-guides': {
      await store.dispatch('GET_SINGLE_AGENCY_FROM_SERVER', to.params.id).then(() => {
        store.dispatch('SET_LOADING', true);
        next();
      }).catch(async () => {
        await store.dispatch('GET_SINGLE_GUIDE_FROM_SERVER', to.params.id).then(() => {
          store.dispatch('SET_LOADING', true);
          next();
        }).catch(() => {
          store.dispatch('SET_LOADING', true);
          next({ path: '/notfound' });
        });
      });
      break;
    }
  }
};

// Записываем последний предществующий маршрут
router.afterEach((to, from) => {
  localStorage.setItem('prevRouteKey', from.path);
});

// Записываем и считываем Id остановки, панели и файл конфигурации из параметров или локального хранилища
router.beforeEach((to, from, next) => {
  const stationInStore = store.getters.GET_STOP;
  const stationIdInLocalStorage = localStorage.getItem('stationId');
  const stationId = to.query.stop_id ? to.query.stop_id : stationIdInLocalStorage;

  const panelInStore = store.getters.GET_PANEL;
  const panelInLocalStorage = localStorage.getItem('panelId');
  const panelId = to.query.panel_id ? to.query.panel_id : panelInLocalStorage;

  const configInStore = store.getters.GET_CONFIG;
  const configId = to.query.config_id;
  const currentRegion = process.env.VUE_APP_CURRENT_REGION;
  
  const arrowsInStore = store.getters.GET_ARROWS;
  
  // Составляем массив из query-параметров для виджета навигации со стрелками направлений, названием и времени до объекта
  function prepareNavigation(keys) {
    const prepared = [];
    
    keys.map(arr => {
      const obj = {};
      arr.map(item => {
        if (to.query[item]) {
          const objKey = item.slice(0, -2);
          obj[objKey] = to.query[item];
        }
      });
      !isEmpty(obj) ? prepared.push(obj) : null;
    });
    
    return !isEmpty(prepared) ? prepared : [];
  }
  
  // Проверяем если массив данных для виджета навигации уже присутствует в хранилище и если нет, то сохраняем новый
  if (isEmpty(arrowsInStore)) {
    const navigationKeys = [['title_1', 'time_1', 'direction_1'], ['title_2', 'time_2', 'direction_2']];
    const nav = prepareNavigation(navigationKeys);
    store.commit("SET_ARROWS", nav);
  }

  // Проверяем если stop_id присутствует в параметрах или в локальном хранилище и делаем по нему запрос к серверу
  if (stationId && isEmpty(stationInStore)) {
    store.dispatch('GET_STATIONS_FROM_SERVER', stationId);
    localStorage.setItem('stationId', stationId);
  }

  // Проверяем если panel_id присутствует в параметрах или в локальном хранилище и делаем по нему запрос к серверу
  if (panelId && isEmpty(panelInStore)) {
    store.dispatch('GET_PANEL_FROM_SERVER', panelId);
    localStorage.setItem('panelId', panelId);
  }

  // Проверяем если файл конфигурации присутствует в хранилище и устанавливаем файл региона, если он присутствует в параметрах или устанавливаем стандартный файл конфигурации
  if (isEmpty(configInStore)) {
    if (configId && currentRegion) {
      const currentConfig = require(`@/regions-config/${currentRegion}/${configId}.config.js`).default;
      store.commit("SET_CONFIG", currentConfig);
    } else {
      const currentConfig = require('@/regions-config/default.js').default;
      store.commit("SET_CONFIG", currentConfig);
    }
  }

  next();
});

export default router;
