import axios from "axios";
import i18n from "@/i18n";

const state = {
  banners: [],
};

const getters = {
  GET_BANNERS: state => state.banners,
};

const mutations = {
  SET_BANNERS: (state, payload) => {
    state.banners = payload;
  },
}

const actions = {
  GET_BANNERS_FROM_SERVER: async ({ commit }, id) => {
    const singleCity = id ? `&city_id=${id}` : '';
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/panel-banners?lang=${i18n.locale}${singleCity}`);
    commit('SET_BANNERS', data.data);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
