export default {
  configName: 'default',
  sleepModeTime: 600000, // in seconds
  bannersRotation: 12, // in seconds
  isSingleCity: false,
  placesRadius: 10000, // min 100 max 10000
  isTransportOn: true, // true - транспорт включен, false - транспорт выключен
  theme: {
    switchBySunset: true, // если true, то тема автоматически переключается в зависимости от заказата и рассвета
    currentTheme: 'dark' // принудительная установка постоянной цветовой темы, используется только если switchBySunset = false; варианты: 'default' - светлая тема или 'dark' - тёмная тема
  },
  header: {
    info: {
      title: true,
      address: true
    },
    widgets: {
      display: true,
      type: 'navigation',
    }
  },
  mainScreen: {
    banner: {
      display: false,
    },
    announce: {
      display: true,
      type: 'events',
    },
    stories: {
      display: true,
      storyCounter: 10, // счётчик истории в секундах, по исчетечении которых история перелистывается на следующую
    }
  },
  voiceSearch: {
    type: 'voice', // or 'text'
  },
  footer: {
    buttons: {
      search: {
        display: true,
      },
      transport: {
        display: true,
      },
      help: {
        display: true,
      },
    }
  },
};
