const state = {
  config: {},
};

const getters = {
  GET_CONFIG: state => state.config,
};

const mutations = {
  SET_CONFIG: (state, payload) => {
    state.config = payload;
  },
}

export default {
  state,
  getters,
  mutations,
};
