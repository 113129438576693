import axios from "axios";
import i18n from "@/i18n";

const state = {
  excursions: [],
  filteredExcursions: []
};

const getters = {
  GET_EXCURSIONS: state => state.excursions,
  GET_FILTERED_EXCURSIONS: state => state.filteredExcursions,
};

const mutations = {
  SET_EXCURSIONS: (state, payload) => {
    state.excursions = payload;
  },
  SET_FILTERED_EXCURSIONS: (state, payload) => {
    state.filteredExcursions = payload;
  },
  ADD_SINGLE_EXCURSION: (state, payload) => {
    state.excursions.push(payload);
  }
}

const actions = {
  GET_EXCURSIONS_FROM_SERVER: async ({ commit }, id) => {
    const singleCity = id ? `&city_id=${id}` : '';
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/excursions?sort=avg&lang=${i18n.locale}${singleCity}&count=1000&show=duration_int,distance_int,cost,level,label,season,agency,age_restriction,qr_code,description,id,images,location,title,category`);
    commit('SET_EXCURSIONS', data.data);
  },
  GET_FILTERED_EXCURSIONS_FROM_SERVER: async ({ commit }, payload) => {
    const singleCity = payload.cityId ? `&city_id=${payload.cityId}` : '';
    const category_id = payload.category ? `&category_id=${payload.category}` : '';

    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/excursions?sort=avg&lang=${i18n.locale}${singleCity}${category_id}&count=1000&show=duration_int,distance_int,cost,level,label,season,agency,age_restriction,qr_code,description,id,images,location,title,category`);
    commit('SET_FILTERED_EXCURSIONS', data.data);
  },
  GET_SINGLE_EXCURSION_FROM_SERVER: async ({ commit }, id) => {
    const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL}/excursions/${id}`);
    commit('ADD_SINGLE_EXCURSION', data.data);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
