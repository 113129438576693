<template>
  <div class="footer" :class="{ 'transparent' : isStoryScreen }">
    <r-button
        :disabled="false"
        :title="$t('buttons.back')"
        size="laripha"
        icon="answer"
        width="wide"
        type="secondary"
        rounded
        v-if="isVisible && !isStoryScreen && !isSleepScreen"
        @click="goBack"
    />

    <r-button
        :disabled="false"
        :title="$t('buttons.to_main')"
        size="laripha"
        icon="answer"
        width="wide"
        type="secondary"
        rounded
        v-if="isVisible && isStoryScreen"
        @click="goMain"
    />

    <r-button
        :disabled="false"
        :title="$t('buttons.search')"
        size="laripha"
        color="rocky"
        icon="search"
        width="wide"
        rounded
        v-if="$currentConfig.footer.buttons.search.display && !isSearchScreen"
        @click="goSearch"
    />

    <r-button
        :disabled="false"
        :title="$t('buttons.services')"
        size="laripha"
        color="fargo"
        icon="help"
        width="wide"
        rounded
        @click="goServices"
    />

    <r-button
        :disabled="false"
        :title="$t('buttons.language')"
        size="laripha"
        type="secondary"
        width="wide"
        rounded
        v-if="!isVisible || isSleepScreen"
        @click="changeLocale"
    />
  </div>
</template>

<script>
export default {
  name: "Footer",

  computed: {
    isVisible() {
      return !(this.$route.path === '/');
    },
    isStoryScreen() {
      return this.$route.path.includes('story');
    },
    isSleepScreen() {
      return this.$route.path.includes('sleep');
    },
    isSearchScreen() {
      return this.$route.path.includes('search');
    }
  },

  methods: {
    goBack() {
      const prevPath = localStorage.getItem('prevRouteKey');
      if (prevPath.includes('sleep')) {
        this.$router.go(-2);
      } else {
        this.$router.back();
      }
    },
    goMain() {
      this.$router.push('/');
    },
    goServices() {
      this.$router.push('/services');
    },
    goSearch() {
      this.$router.push('/search');
    },
    changeLocale() {
      if (this.$i18n.locale === 'ru') {
        this.$i18n.locale = 'en';
        this.$moment.locale(this.$i18n.locale);
      } else {
        this.$i18n.locale = 'ru';
        this.$moment.locale(this.$i18n.locale);
      }
    },
  },
}
</script>

<style lang="scss">
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 112px;

  background: var(--rir-trainspotting);
  box-shadow: 0 8px 32px rgba(12, 16, 24, 0.12);

  padding: 24px;

  z-index: 1100;

  &.transparent {
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
  }

  .RButton:nth-child(2):not(:first-child):not(:last-child) {
    margin-right: 16px;
    margin-left: 16px;
  }

  .RButton:nth-child(2) {
    margin-left: 16px;
  }

  .isvisible {
    display: none;
  }
}

</style>
