import axios from "axios";
import i18n from "@/i18n";

const state = {
  routes: [],
  filteredRoutes: [],
};

const getters = {
  GET_ROUTES: state => state.routes,
  GET_FILTERED_ROUTES: state => state.filteredRoutes,
};

const mutations = {
  SET_ROUTES: (state, payload) => {
    state.routes = payload;
  },
  SET_FILTERED_ROUTES: (state, payload) => {
    state.filteredRoutes = payload;
  },
  ADD_SINGLE_ROUTE: (state, payload) => {
    state.routes.push(payload);
  }
}

const actions = {
  GET_ROUTES_FROM_SERVER: async ({ commit }, id ) => {
    const singleCity = id ? `&city_id=${id}` : '';
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/routes?sort=avg&lang=${i18n.locale}${singleCity}&resolution=small&count=1000&show=qr_code,city,description,id,images,location,title,category,duration_int,distance_int,season,level,age_restriction`);
    commit('SET_ROUTES', data.data);
  },
  GET_FILTERED_ROUTES_FROM_SERVER: async ({ commit }, payload) => {
    const singleCity = payload.cityId ? `&city_id=${payload.cityId}` : '';
    const category_id = payload.category ? `&category_id=${payload.category}` : '';
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/routes?sort=avg${category_id}${singleCity}&lang=${i18n.locale}&count=1000&resolution=small&show=qr_code,city,description,id,images,location,title,category,duration_int,distance_int,season,level,age_restriction`);
    commit('SET_FILTERED_ROUTES', data.data);
  },
  GET_SINGLE_ROUTE_FROM_SERVER: async ({ commit }, id) => {
    const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL}/routes/${id}`);
    commit('ADD_SINGLE_ROUTE', data.data);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
