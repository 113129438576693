<template>
  <div class="navigation-widget">
    <r-row>
      <r-col v-for="(item, index) in navigation" :key="index" :cols="{ widest: 12, wide: 12, middle: 12, narrow: 6 }">
        <NavigationCard :nav="item" />
      </r-col>
    </r-row>
  </div>
</template>

<script>
import NavigationCard from "@/components/NavigationCard/NavigationCard";

export default {
  name: "NavigationWidget",
  
  components: { NavigationCard },
  
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.navigation-widget {
  padding-top: 8px;
  padding-bottom: 24px;
}
</style>
