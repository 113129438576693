import axios from "axios";
import i18n from "@/i18n";

const state = {
  agencies: [],
};

const getters = {
  GET_TRAVEL_AGENCIES: state => state.agencies,
};

const mutations = {
  SET_TRAVEL_AGENCIES: (state, payload) => {
    state.agencies = payload;
  },
  ADD_SINGLE_AGENCY: (state, payload) => {
    state.agencies.push(payload);
  }
}

const actions = {
  GET_TRAVEL_AGENCIES_FROM_SERVER: async ({ commit }, id) => {
    const singleCity = id ? `&city_id=${id}` : '';
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/travel-agencies?lang=${i18n.locale}${singleCity}&count=1000`);
    commit('SET_TRAVEL_AGENCIES', data.data);
  },
  GET_SINGLE_AGENCY_FROM_SERVER: async ({ commit }, id) => {
    const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL}/travel-agencies/${id}`);
    commit('ADD_SINGLE_AGENCY', data.data);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
