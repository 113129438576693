import axios from "axios";
import i18n from "@/i18n";

const state = {
  places: [],
  filteredPlaces: [],
};

const getters = {
  GET_PLACES: state => state.places,
  GET_FILTERED_PLACES: state => state.filteredPlaces,
};

const mutations = {
  SET_PLACES: (state, payload) => {
    state.places = payload;
  },
  SET_FILTERED_PLACES: (state, payload) => {
    state.filteredPlaces = payload;
  },
  ADD_SINGLE_PLACE: (state, payload) => {
    state.places.push(payload);
  }
}

const actions = {
  GET_PLACES_FROM_SERVER: async ({ commit }, payload) => {
    const singleCity = payload.cityId ? `&city_id=${payload.cityId}` : '';
    const longitude = payload.longitude ? `&nearby[longitude]=${payload.longitude}` : '';
    const latitude = payload.latitude ? `&nearby[latitude]=${payload.latitude}` : '';
    const radius = payload.radius ? `&nearby[radius]=${payload.radius}` : '';

    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/places?resolution=small${singleCity}${longitude}${latitude}${radius}&lang=${i18n.locale}&count=1000&sort=created_at&show=additions,address,contacts,qr_code,description,id,images,location,title,work_hours,category`);
    commit('SET_PLACES', data.data);
  },
  GET_FILTERED_PLACES_FROM_SERVER: async ({ commit }, payload) => {
    const singleCity = payload.cityId ? `&city_id=${payload.cityId}` : '';
    const category_id = payload.category ? `&category_id=${payload.category}` : '';
    const longitude = payload.longitude ? `&nearby[longitude]=${payload.longitude}` : '';
    const latitude = payload.latitude ? `&nearby[latitude]=${payload.latitude}` : '';
    const radius = payload.radius ? `&nearby[radius]=${payload.radius}` : '';

    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/places?&lang=${i18n.locale}${singleCity}${longitude}${latitude}${radius}${category_id}&count=1000&sort=created_at&resolution=small&show=additions,address,contacts,qr_code,description,id,images,location,title,work_hours,category`);
    commit('SET_FILTERED_PLACES', data.data);
  },
  GET_SINGLE_PLACE_FROM_SERVER: async ({ commit }, id) => {
    const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL}/places/${id}`);
    commit('ADD_SINGLE_PLACE', data.data);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
