import axios from "axios";
import i18n from "@/i18n";

const state = {
  excursionsCategories: [],
};

const getters = {
  GET_EXCURSIONS_CATEGORIES: state => state.excursionsCategories,
};

const mutations = {
  SET_EXCURSIONS_CATEGORIES: (state, payload) => {
    state.excursionsCategories = payload;
  },
}

const actions = {
  GET_EXCURSIONS_CATEGORIES_FROM_SERVER: async ({ commit }, id) => {
    const singleCity = id ? `&city_id=${id}` : '';
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/categories/excursions?lang=${i18n.locale}${singleCity}`);
    commit('SET_EXCURSIONS_CATEGORIES', data.data);
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
};
