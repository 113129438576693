<template>
  <div class="loader" :style="`height: ${height}`">
    <r-spinner :size="size" />
  </div>
</template>

<script>
export default {
  name: "Loader",

  props: {
    height: {
      type: [String, Number],
      default: () => '100vh'
    },
    size: {
      type: String,
      default: () => 'synallis'
    }
  }
}
</script>

<style lang="scss">
.loader {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
