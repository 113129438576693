<template>
  <div class="transport-widget">
    <r-row>
      <r-col v-for="item in sortedByArrival" :key="item.no" :cols="{ widest: 12, wide: 12, middle: 12, narrow: 3 }">
        <ArrivalCard :arrival="item" />
      </r-col>
    </r-row>
  </div>
</template>

<script>
import ArrivalCard from "@/components/ArrivalCard/ArrivalCard";

export default {
  name: "TransportWidget",

  components: {
    ArrivalCard,
  },

  computed: {
    sortedByArrival: function() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.arrival.sort((a, b) => a.status - b.status);
    }
  },

  data() {
    return {
      arrival: [
        {
          no: 'T11',
          status: 15,
        },
        {
          no: 'T15',
          status: 12,
        },
        {
          no: 'T12',
          status: 0,
        },
        {
          no: 'M431',
          status: 6,
        },
      ]
    }
  },

  methods: {

  },
}
</script>

<style lang="scss">
.transport-widget {
  padding-top: 8px;
  padding-bottom: 24px;
}
</style>
