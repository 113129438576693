import axios from "axios";
import i18n from "@/i18n";

const state = {
  eventsCategories: [],
};

const getters = {
  GET_EVENTS_CATEGORIES: state => state.eventsCategories,
};

const mutations = {
  SET_EVENTS_CATEGORIES: (state, payload) => {
    state.eventsCategories = payload;
  },
}

const actions = {
  GET_EVENTS_CATEGORIES_FROM_SERVER: async ({ commit }, id) => {
    const singleCity = id ? `&city_id=${id}` : '';
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/categories/events?lang=${i18n.locale}${singleCity}`);
    commit('SET_EVENTS_CATEGORIES', data.data);
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
};
