import API from '@/api/api';
import axios from 'axios';
import i18n from '@/i18n';
import moment from 'moment';

const api = new API();

const state = {
  collection: [],
  collectionCategories: [],
  filteredCollection: []
};

const getters = {
  GET_COLLECTION: state => state.collection,
  GET_COLLECTION_CATEGORIES: state => state.collectionCategories,
  GET_FILTERED_COLLECTION: state => state.filteredCollection
};

const mutations = {
  SET_COLLECTION: (state, payload) => {
    state.collection = payload;
  },
  SET_FILTERED_COLLECTION: (state, payload) => {
    state.filteredCollection = payload;
  },
  SET_COLLECTION_CATEGORIES: (state, payload) => {
    state.collectionCategories = payload;
  }
}

const actions = {
  GET_COLLECTION_FROM_SERVER: async ({ commit }, payload) => {
    const type = payload.type ? payload.type : '';
    const singleCity = payload.cityId ? `&city_id=${payload.cityId}` : '';
    const today = moment().format('YYYY-MM-DD');
    const category_id = payload.category ? `&category_id=${payload.category}` : '';
    const longitude = payload.longitude ? `&nearby[longitude]=${payload.longitude}` : '';
    const latitude = payload.latitude ? `&nearby[latitude]=${payload.latitude}` : '';
    const radius = payload.radius ? `&nearby[radius]=${payload.radius}` : '';

    const eventsParams = `${type}?${singleCity}&started_at=${today}${category_id}&lang=${i18n.locale}&count=1000&sort=created_at&resolution=small`;
    const excursionsParams = `${type}?${singleCity}${category_id}&lang=${i18n.locale}&sort=avg&count=1000&show=duration_int,distance_int,cost,level,label,season,agency,age_restriction,qr_code,description,id,images,location,title,category`;
    const routesParams = `${type}?${singleCity}${category_id}&lang=${i18n.locale}&sort=avg&count=1000&resolution=small&show=qr_code,city,description,id,images,location,title,category,duration_int,distance_int,season,level,age_restriction`;
    const placesParams = `${type}?${singleCity}${category_id}${longitude}${latitude}${radius}&lang=${i18n.locale}&count=1000&sort=created_at&resolution=small&show=additions,address,contacts,qr_code,description,id,images,location,title,work_hours,category`;

    const categories = await api.getCategories(payload.type, payload.cityId);
    commit('SET_COLLECTION_CATEGORIES', categories.data);

    if (payload.type === 'events') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${eventsParams}`);
      commit('SET_COLLECTION', data.data);
    }
    if (payload.type === 'excursions') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${excursionsParams}`);
      commit('SET_COLLECTION', data.data);
    }
    if (payload.type === 'routes') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${routesParams}`);
      commit('SET_COLLECTION', data.data);
    }
    if (payload.type === 'places') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${placesParams}`);
      commit('SET_COLLECTION', data.data);
    }
  },
  GET_FILTERED_COLLECTIONS_FROM_SERVER: async ({ commit }, payload) => {
    const type = payload.type ? payload.type : '';
    const singleCity = payload.cityId ? `&city_id=${payload.cityId}` : '';
    const started_at = payload.date[0] ? `&started_at=${payload.date[0]}` : '';
    const stopped_at = payload.date[1] ? `&stopped_at=${payload.date[1]}` : '';
    const category_id = payload.category ? `&category_id=${payload.category}` : '';
    const longitude = payload.longitude ? `&nearby[longitude]=${payload.longitude}` : '';
    const latitude = payload.latitude ? `&nearby[latitude]=${payload.latitude}` : '';
    const radius = payload.radius ? `&nearby[radius]=${payload.radius}` : '';

    const eventsParams = `${type}?${singleCity}${started_at}${stopped_at}${category_id}&lang=${i18n.locale}&count=1000&sort=created_at&resolution=small`;
    const excursionsParams = `${type}?${singleCity}${category_id}&lang=${i18n.locale}&sort=avg&count=1000&show=duration_int,distance_int,cost,level,label,season,agency,age_restriction,qr_code,description,id,images,location,title,category`;
    const routesParams = `${type}?${singleCity}${category_id}&lang=${i18n.locale}&sort=avg&count=1000&resolution=small&show=qr_code,city,description,id,images,location,title,category,duration_int,distance_int,season,level,age_restriction`;
    const placesParams = `${type}?${singleCity}${category_id}${longitude}${latitude}${radius}&lang=${i18n.locale}&count=1000&sort=created_at&resolution=small&show=additions,address,contacts,qr_code,description,id,images,location,title,work_hours,category`;

    if (payload.type === 'events') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${eventsParams}`);
      commit('SET_FILTERED_COLLECTION', data.data);
    }
    if (payload.type === 'excursions') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${excursionsParams}`);
      commit('SET_FILTERED_COLLECTION', data.data);
    }
    if (payload.type === 'routes') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${routesParams}`);
      commit('SET_FILTERED_COLLECTION', data.data);
    }
    if (payload.type === 'places') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${placesParams}`);
      commit('SET_FILTERED_COLLECTION', data.data);
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
