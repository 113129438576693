import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import rir from '@/plugins/rir-lib/RirLib';
import i18n from './i18n';
import Vue2TouchEvents from 'vue2-touch-events';
import lineClamp from 'vue-line-clamp';
import { echoInstance } from '@/api/socket';
import API from '@/api/api';
import computed from '@/mixins/computed';
import methods from '@/mixins/methods';

const moment = require('moment');
require('moment/locale/ru');
moment.updateLocale('ru', { week: { dow: 1 }} );
Vue.use(require('vue-moment'), { moment });
Vue.use(Vue2TouchEvents);
Vue.use(lineClamp);

Vue.mixin({
  computed,
  methods,
});

Vue.prototype.$api = new API();
Vue.prototype.$echo = echoInstance;

Vue.config.productionTip = false;

new Vue({
  $rir: rir,
  store: store,
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
