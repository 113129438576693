import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// Region config
import config from './config';

// Content
import events from './content/events';
import places from './content/places';
import stories from './content/stories';
import news from './content/news';

// Collections
import collections from '@/store/collections/collections';

// Categories
import eventsCategories from '@/store/categories/events';
import routesCategories from '@/store/categories/routes';
import placesCategories from '@/store/categories/places';
import excursionsCategories from '@/store/categories/excursions';

// Agencies
import travelAgencies from '@/store/agencies/travel-agencies';
import guides from '@/store/agencies/guides';

// Panel
import panel from '@/store/panel/panel';
import navigation from '@/store/panel/navigation';
import hotlines from '@/store/panel/hotlines';
import banners from '@/store/panel/banners';
import arrows from '@/store/panel/arrows-navigation';

// Trips
import routes from './trips/routes';
import excursions from './trips/excursions';

// Transport
import stations from '@/store/transport/stations';

export default new Vuex.Store({
  modules: {
    panel,
    navigation,
    hotlines,
    arrows,
    collections,
    config,
    events,
    places,
    stories,
    news,
    routes,
    excursions,
    stations,
    eventsCategories,
    routesCategories,
    placesCategories,
    excursionsCategories,
    travelAgencies,
    guides,
    banners,
  },
  strict: process.env.NODE_ENV !== 'production',
});
