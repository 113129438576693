export default {
  $removeLinkTags(str) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
    }

    return str.replace( /<(?:a\b[^>]*>|a>)/ig, '');
  },

  $makeCategoryTagsSet(data) {
    const dataSet = [...data];
    const colors = ['fargo', 'lebowski', 'aquaman', 'fightclub'];

    for (let i = 0; i < dataSet.length; i++) {
      dataSet[i].color = colors[i % colors.length];
    }

    return [{id: 'all', title: 'Все', color: 'gentlemen'}, ...dataSet];
  },
  $makePlacesPins(data, categories) {
    const places = [...data];

    for (let i = 0; i < places.length; i++) {
      let category = categories.find(item => item.id === places[i].category?.id)
      places[i].color = category?.color;
    }

    return places;
  },

  $makeDatesTagsSet() {
    return [
      {id: 'today', title: `${this.$t('dates.today')}`, color: 'rocky'},
      {id: 'tomorrow', title: `${this.$t('dates.tomorrow')}`, color: 'rocky'},
      {id: 'weekend', title: `${this.$t('dates.weekend')}`, color: 'rocky'},
      {id: 'month', title: `${this.$t('dates.month')}`, color: 'rocky'},
    ];
  },

  $duration(value, card) {
    if (value > 2880) {
      return `${Math.round(value / 60 / 24)} ${this.$t('common.units.day')}`
    } else {
      const obj = this.$moment.utc(this.$moment.duration(value * 60, 'seconds').asMilliseconds()).toObject()
      const day = obj.date - 1
      const hours = obj.hours
      const minutes = obj.minutes
      if (day) {
        return hours
          ? `${day} ${this.$t('common.units.day')} ${hours} ${this.$t('common.units.hours')}`
          : `${day} ${this.$t('common.units.day')}`
      } else if (hours && hours < 12 && minutes && card === 'card') {
        const hour = Math.round(value / 60 * 10) / 10
        const floor = Math.floor(hour)
        const residue = hour % floor
        return residue < 0.25
          ? `${floor} ${this.$t('common.units.hours')}`
          : (residue < 0.75
            ? `${floor + 0.5} ${this.$t('common.units.hours')}`
            : `${floor + 1} ${this.$t('common.units.hours')}`)
      } else if (hours && hours < 12 && minutes) {
        return `${hours} ${this.$t('common.units.hours')} ${minutes} ${this.$t('common.units.minutes')}`
      } else if (hours) {
        return `${Math.round(value / 60)} ${this.$t('common.units.hours')}`
      } else {
        return `${minutes} ${this.$t('common.units.minutes')}`
      }
    }
  },

  $distance(value, card) {
    if (value > 999) {
      if (card === 'card') {
        return value > 9999
          ? `${Math.round(value / 1000)} ${this.$t('common.units.kilometer')}`
          : `${Math.round((value / 1000) * 10) / 10} ${this.$t('common.units.kilometer')}`
      } else {
        const dist = value.toString().split('').reverse()
        const m = parseInt(dist.slice(0, 3).reverse().join(''))
        const km = dist.slice(3).reverse().join('')
        return m
          ? `${km} ${this.$t('common.units.kilometer')} ${m} ${this.$t('common.units.meter')}`
          : `${km} ${this.$t('common.units.kilometer')}`
      }
    } else {
      return `${value} ${this.$t('common.units.meter')}`
    }
  },

  $truncate(str, n) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
  },
}
