<template>
  <div id="app">
    <div class="screen" v-if="GET_IS_LOADING" :style="`padding-top: ${GET_HEAD_HEIGHT}px`">
      <div class="screen__info px-4" :class="isHeadPanelShadow ? 'screen__info--shadow' : ''" ref="headPanel">
        <Header/>

        <PanelTitle v-if="(panelInfo.title && $currentConfig.header.info.title) || (panelInfo.address && $currentConfig.header.info.address)" :info="panelInfo" />

        <TransportWidget v-if="isTransportWidget" />
        <NavigationWidget v-if="isNavigationWidget && arrowsNavigation" :navigation="arrowsNavigation" />
      </div>

      <router-view/>

      <Footer/>
    </div>

    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { loadYmap } from 'vue-yandex-maps'
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import Loader from "@/components/Loader/Loader";
import PanelTitle from "@/components/PanelTitle/PanelTitle";
import TransportWidget from "@/components/Widgets/TransportWidget";
import NavigationWidget from "@/components/Widgets/NavigationWidget";
import Vue from "vue";

export default {
  name: 'App',

  components: {
    NavigationWidget,
    TransportWidget,
    PanelTitle,
    Loader,
    Header,
    Footer,
  },

  data() {
    return {
      timer: null,
      isHeadPanelShadow: false,
      settings: {
        apiKey: '3ce74472-614d-48ae-be3b-43d1739a0368',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
        controls: [],
      }
    }
  },

  computed: {
    ...mapGetters(['GET_SINGLE_CITY', 'GET_HEAD_HEIGHT', 'GET_PANEL', 'GET_STOP', 'GET_NAVIGATION', 'GET_IS_LOADING', 'GET_BANNERS', 'GET_ARROWS']),

    isTransportWidget() {
      return this.$currentConfig.header.widgets.display &&
          this.$currentConfig.header.widgets.type === 'transport' &&
          this.GET_NAVIGATION;
    },
    isNavigationWidget() {
      return this.$currentConfig.header.widgets.display &&
          this.$currentConfig.header.widgets.type === 'navigation'
    },
    panelCoords() {
      return {
        longitude: this.GET_PANEL.longitude,
        latitude: this.GET_PANEL.latitude,
        radius: this.$currentConfig.placesRadius
      }
    },
    panelInfo() {
      return {
        title: this.GET_PANEL?.title,
        address: this.GET_PANEL?.address,
      }
    },
    singleCityId() {
      if (this.$currentConfig.isSingleCity) {
        return {
          cityId: this.GET_SINGLE_CITY[0].id
        }
      }
      return null;
    },
    arrowsNavigation() {
      return this.GET_ARROWS.length ? this.GET_ARROWS : null;
    }
  },

  async mounted() {
    await loadYmap({ ...this.settings, debug: true });
    Vue.prototype.$ymaps = window.ymaps;

    if (!this.$currentConfig.theme.switchBySunset) {
      this.$rir.changeTheme(this.$currentConfig.theme.currentTheme);
    }

    this.listenUserActivity();
    this.awaitStore();
    this.awaitTransport();
    this.setScrollListener();

    this.$echo.channel('model-update-channel')
        .listen('.model.updated', (response) => {
          switch (response.entity) {
            case 'news':
              this.$store.dispatch('GET_NEWS_FROM_SERVER');
              break;
            case 'events':
              this.$store.dispatch('GET_EVENTS_FROM_SERVER');
              break;
            case 'places':
              this.$store.dispatch('GET_PLACES_FROM_SERVER', this.panelCoords);
              break;
            case 'routes':
              this.$store.dispatch('GET_ROUTES_FROM_SERVER');
              break;
            case 'excursions':
              this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER');
              break;
            case 'stories':
              this.$store.dispatch('GET_STORIES_FROM_SERVER');
              break;
            default:
              break;
          }
        }).error((error) => console.log(error));
  },

  watch: {
    '$i18n.locale': function() {
      this.awaitStore();
    },
    '$route': function() {
      this.resetSleepMode();
    }
  },

  methods: {
    listenUserActivity() {
      const _this = this;
      const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart', 'touchmove'];
      events.forEach(function(name) {
        document.addEventListener(name, _this.resetSleepMode, true);
      });
    },
    resetSleepMode() {
      if (this.$route.path !== "/sleep" && this.GET_BANNERS.length) {
        const period = parseInt(this.$currentConfig.sleepModeTime) * 1000;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.goToSleepMode();
        }, period);
      } else {
        clearTimeout(this.timer);
      }
    },
    awaitStore() {
      if (this.$currentConfig.isSingleCity) {
        this.$store.dispatch('GET_SINGLE_CITY_FROM_SERVER').then(() => {
          Promise.allSettled([
            this.$store.dispatch('GET_NAVIGATION_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_EVENTS_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_STORIES_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_NEWS_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_ROUTES_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_TRAVEL_AGENCIES_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_GUIDES_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_EVENTS_CATEGORIES_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_ROUTES_CATEGORIES_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_PLACES_CATEGORIES_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_EXCURSIONS_CATEGORIES_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_HOTLINES_FROM_SERVER', this.singleCityId.cityId),
            this.$store.dispatch('GET_BANNERS_FROM_SERVER', this.singleCityId.cityId),
          ]);
        }).then(() => {
          const options = this.panelCoords;
          options.cityId = this.singleCityId.cityId;
          this.$store.dispatch('GET_PLACES_FROM_SERVER', options);
        }).then(() => {
          this.$store.dispatch('SET_LOADING', true);
        }).finally(() => {
          this.resetSleepMode();
          this.getHeadPanelHeight();
        });
      } else {
        Promise.allSettled([
          this.$store.dispatch('GET_NAVIGATION_FROM_SERVER'),
          this.$store.dispatch('GET_EVENTS_FROM_SERVER'),
          this.$store.dispatch('GET_STORIES_FROM_SERVER'),
          this.$store.dispatch('GET_NEWS_FROM_SERVER'),
          this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER'),
          this.$store.dispatch('GET_ROUTES_FROM_SERVER'),
          this.$store.dispatch('GET_TRAVEL_AGENCIES_FROM_SERVER'),
          this.$store.dispatch('GET_GUIDES_FROM_SERVER'),
          this.$store.dispatch('GET_EVENTS_CATEGORIES_FROM_SERVER'),
          this.$store.dispatch('GET_ROUTES_CATEGORIES_FROM_SERVER'),
          this.$store.dispatch('GET_PLACES_CATEGORIES_FROM_SERVER'),
          this.$store.dispatch('GET_EXCURSIONS_CATEGORIES_FROM_SERVER'),
          this.$store.dispatch('GET_HOTLINES_FROM_SERVER'),
          this.$store.dispatch('GET_BANNERS_FROM_SERVER'),
        ]).then(() => {
          this.$store.dispatch('GET_PLACES_FROM_SERVER', this.panelCoords);
        }).then(() => {
          this.$store.dispatch('SET_LOADING', true);
        }).finally(() => {
          this.resetSleepMode();
          this.getHeadPanelHeight();
        });
      }
    },
    awaitTransport() {
      Promise.all([]).finally(() => {
        //this.$store.dispatch('SET_LOADING', true);
      });
    },
    getHeadPanelHeight() {
      this.$store.commit('SET_HEAD_HEIGHT', this.$refs?.headPanel?.clientHeight);
    },
    setScrollListener() {
      document.addEventListener('scroll', () => {
        this.isHeadPanelShadow = window.scrollY > 0;
      });
    },
    goToSleepMode() {
      if (this.$route.path !== '/sleep') this.$router.push('/sleep');
    }
  }
}
</script>

<style lang="scss">
html {
  width: 100%;
  height: 100vh;

  background: var(--rir-trainspotting);
}

body,
#app {
  height: 100%;
}

.screen {
  position: relative;
  height: 100%;

  padding-top: 248px;

  &__info {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    background: var(--rir-trainspotting);
    transition: box-shadow .3s ease;

    z-index: 1100;

    &:after {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      width: 100%;
      height: 100%;

      opacity: 0;

      box-shadow: 0 8px 32px rgba(12, 16, 24, 0.12);
      transition: opacity 0.3s ease-in-out;

      z-index: -1;
    }

    &--shadow {
      &:after {
        opacity: 1;
      }
    }
  }
}
.details {
  display: flex;
  word-break: break-word;
  margin-bottom: 16px;

  .RIcon {
    flex-shrink: 0;
    margin-top: 2px;
    margin-right: 8px;
  }

  span {
    color: var(--rir-harakiri);
  }

  &:not(:first-child) {
    margin-top: 8px;
  }
}
</style>
