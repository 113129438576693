import Vue from 'vue';
import Rir from '@rir/vue-library';
import '@rir/vue-library/dist/RirLib.css';

Vue.use(Rir);
// Options требуется получить от DS сервера
const options = {
  fixPointGrid: 'narrow',

  // gridsName: {
  //   filters: {
  //     widest: {
  //       column: 12,
  //       columnGap: 32,
  //       rowGap: 32,
  //       contentGapLeft: 40,
  //       contentGapRight: 40
  //     },
  //     wide: {
  //       column: 12,
  //       columnGap: 32,
  //       rowGap: 32,
  //       contentGapLeft: 40,
  //       contentGapRight: 40
  //     },
  //     middle: {
  //       column: 6,
  //       columnGap: 24,
  //       rowGap: 24,
  //       contentGapLeft: 32,
  //       contentGapRight: 32
  //     },
  //     narrow: {
  //       column: 6,
  //       columnGap: 4,
  //       rowGap: 8,
  //       contentGapLeft: 24,
  //       contentGapRight: 24
  //     }
  //   },
  //}
};

export default new Rir(options);
