<template>
  <div class="time">
    {{ date }} • {{ time }}
  </div>
</template>

<script>
export default {
  name: "Time",

  data() {
    return {
      time: null,
      date: null,
      weekday: null
    };
  },

  created() {
    setInterval(this.getNow, 1000);
  },

  methods: {
    getNow: function() {
      const currentTime = this.$moment();

      this.time = currentTime.format('HH:mm');
      this.date = currentTime.format('D MMMM');
      this.weekday = currentTime.format('dddd');
    },
  },
}
</script>

<style lang="scss">
.time {
  color: var(--rir-harakiri);
}
</style>
