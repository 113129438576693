import axios from "axios";
import i18n from "@/i18n";

const state = {
  news: [],
};

const getters = {
  GET_NEWS: state => state.news,
};

const mutations = {
  SET_NEWS: (state, payload) => {
    state.news = payload;
  },
  ADD_SINGLE_NEWS: (state, payload) => {
    state.news.push(payload);
  }
}

const actions = {
  GET_NEWS_FROM_SERVER: async ({ commit }, id) => {
    const singleCity = id ? `&city_id=${id}` : '';
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/news?resolution=small&lang=${i18n.locale}${singleCity}&count=1000`);
    commit('SET_NEWS', data.data);
  },
  GET_SINGLE_NEWS_FROM_SERVER: async ({ commit }, id) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/news/${id}`);
    commit('ADD_SINGLE_NEWS', data.data);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
