import axios from "axios";

const state = {
  stations: [],
  stop: {},
};

const getters = {
  GET_STATIONS: state => state.stations,
  GET_STOP: state => state.stop,
};

const mutations = {
  SET_STATIONS: (state, payload) => {
    state.stations = payload;
  },
  SET_STOP: (state, payload) => {
    state.stop = payload;
  },
}

const actions = {
  GET_STATIONS_FROM_SERVER: async ({ commit }, id) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/env/station`);
    const currentStop = data.filter(item => item.id == id);
    commit("SET_STATIONS", data);
    commit("SET_STOP", ...currentStop);
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
};
