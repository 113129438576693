<template>
  <div class="navigation-card">
    <div class="navigation-card__arrow" v-if="arrow">
      <r-icon :icon="arrow" fill="rocky" size="24" />
    </div>

    <div class="navigation-card__title harakiri--text burrata" v-if="nav.title">
      {{ nav.title }}
    </div>

    <div class="navigation-card__time harakiri--text brie" v-if="nav.time">
      {{ nav.time }} мин.
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationCard",

  props: {
    nav: {
      type: [Array, Object],
      default: () => {},
    }
  },

  computed: {
    arrow() {
      switch (this.nav.direction) {
        case 'up':
          return 'arrow-tail-up';
        case 'rightup':
          return 'arrow-tail-up-right';
        case 'right':
          return 'arrow-tail-right';
        case 'rightdown':
          return 'arrow-tail-down-right';
        case 'down':
          return 'arrow-tail-down';
        case 'leftdown':
          return 'arrow-tail-down-left';
        case 'left':
          return 'arrow-tail-left';
        case 'leftup':
          return 'arrow-tail-up-left';
        default:
          return '';
      }
    }
  }
}
</script>

<style lang="scss">
.navigation-card {
  position: relative;

  height: 40px;

  display: flex;
  align-items: center;

  overflow: hidden;

  background: var(--rir-apocalypse);
  border-radius: 8px;

  padding: 6px 12px;

  &__arrow {
    display: flex;
    align-content: center;
    flex-shrink: 0;
    margin-right: 12px;
  }

  &__time {
    margin-left: auto;
  }
}
</style>
