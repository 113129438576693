<template>
  <div class="header trainspotting--bg">
    <r-row :wrap="false">
      <r-col :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        <div class="header__container">
          <div class="header__main">
            <Weather/>

            <Time/>
          </div>
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script>
import Weather from "@/components/Weather/Weather";
import Time from "@/components/Time/Time";

export default {
  name: "Header",

  components: {
    Weather,
    Time,
  }
}
</script>

<style lang="scss">
.header {

  &__container {
    height: 72px;
    padding-left: 35px;

    display: flex;
    align-items: center;
  }

  &__main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: relative;

    height: 32px;
    width: 100%;

    padding: 4px 12px 4px 24px;
  }
}
</style>
