import axios from "axios";
import i18n from "@/i18n";

const state = {
  hotlines: [],
};

const getters = {
  GET_HOTLINES: state => state.hotlines,
};

const mutations = {
  SET_HOTLINES: (state, payload) => {
    state.hotlines = payload;
  },
}

const actions = {
  GET_HOTLINES_FROM_SERVER: async ({ commit }, id) => {
    const singleCity = id ? `&city_id=${id}` : '';
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/panel-hotlines?lang=${i18n.locale}${singleCity}`);
    commit('SET_HOTLINES', data.data);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
