<template>
  <div class="weather">
    <img src="@/assets/svg/weather/few-clouds.svg" alt="" class="weather__img" />
    <div class="weather__temp">
      {{ weather.real }}°
    </div>
  </div>
</template>

<script>
export default {
  name: "Weather",

  data() {
    return {
      weather: {
        real: null,
        icon: null,
        desc: null,
        sunrise: null,
        sunset: null,
      },
    }
  },

  mounted() {
    this.fetchWeather();
  },

  created() {
    setInterval(this.fetchWeather, 1000 * 60 * 60);

    if (this.$currentConfig.theme.switchBySunset) {
      setInterval(this.changeThemeBySunset, 1000 * 60);
    }
  },

  watch: {
    '$i18n.locale': function() {
      this.fetchWeather();
    },
  },

  methods: {
    fetchWeather() {
      this.$api.getWeather().then(res => {
        this.weather.real = Math.round(res.main.temp);
        this.weather.icon = res.weather[0].icon;
        this.weather.desc = res.weather[0].description;
        this.weather.sunrise = res.sys?.sunrise;
        this.weather.sunset = res.sys?.sunset ;
      }).finally(() => {
        if (this.$currentConfig.theme.switchBySunset) this.changeThemeBySunset();
      });
    },

    changeThemeBySunset() {
      const sunrise = this.$moment(this.weather.sunrise * 1000);
      const sunset = this.$moment(this.weather.sunset * 1000);
      const currentTime = this.$moment(new Date().getTime());

      if (currentTime.isBetween(sunrise, sunset)) {
        this.$rir.changeTheme('default');
      } else {
        this.$rir.changeTheme('dark');
      }
    }
  }
}
</script>

<style lang="scss">
.weather {

  &__img {
    position: absolute;

    left: -34px;
    top: 0;

    height: 34px;
    width: auto;
  }

  &__temp {
    color: var(--rir-harakiri);
  }
}
</style>
